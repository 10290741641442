<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <div class="main">
    <section class="bordered padded">
      <h1><translate translate-context="*/*/Noun">Groups</translate></h1>
      <p><translate translate-context="*/*/*">Groups allow you to separate resources such as users and machine within your organization.</translate></p>
      <form @submit.prevent="create" v-if="$store.state.user.permissions.client_admin">
        <h2>Create a new group</h2>
        <field-errors :errors="errors.nonFieldErrors" />
        <div class="stackable row">
          <div class="column">
            <div class="field">
              <label for="name">
                <translate translate-context="*/*/*">Name</translate>
              </label>
              <field-errors :errors="errors.fieldErrors" field="name" />
              <input type="text" id="name" name="name" v-model="group.name">
            </div>
          </div>
        </div>
        <app-button :is-loading="isCreating" type="submit">
          <translate translate-context="*/Button/Verb">Save</translate>
        </app-button>
      </form>
      <h2>Existing groups</h2>
      <loading-area class="opaque padded bordered" v-if="isLoading" :is-loading="isLoading">
        <span><translate translate-context="*/*/Loader">Loading groups…</translate></span>
      </loading-area>
      <table v-else-if="results">
        <thead>
          <th><translate translate-context="*/*/*">Name</translate></th>
          <th><translate translate-context="*/*/*">Creation date</translate></th>
          <th><translate translate-context="*/*/*">Members</translate></th>
          <!-- <th><translate translate-context="*/*/*">Actions</translate></th> -->
        </thead>
        <tbody>
          <tr v-for="group in results.results" :key="group.id">
            <td>
              <router-link :to="{name: 'dashboard.group', params: {group: group.id}}">{{ group.name }}</router-link>
            </td>
            <td>{{ prettyDate(group.creation_date) }}</td>
            <td>
              {{ group.members.length }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>
<script>

import http from '@/http'
import { prettyDate } from '@/time'

export default {
  data () {
    return {
      errors: {},
      isLoading: false,
      isCreating: false,
      results: null,
      prettyDate,
      showDeleteModal: false,
      filters: {...this.$route.query},
      group: {
        name: null,
        roles: []
      }
    }
  },
  async created () {    
    await this.fetchData(this.filters)
  },
  methods: {
    async fetchData(filters) {
      let response
      this.isLoading = true
      this.errors = {}
      this.results = null
      try {
        response = await http.get('groups', {params: {...filters}})
        this.results = response.data
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async create() {
      let response
      this.isCreating = true
      this.errors = {}
      try {
        response = await http.post('groups', this.group)
        this.results = response.data
        return this.$router.push({name: 'dashboard.group', params: {group: response.data.id}})
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isCreating = false
      }
    },
  }
}
</script>
