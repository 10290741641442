<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <div class="main">
    <section class="bordered padded">
      <h1><translate translate-context="*/*/Noun">Organization Settings</translate></h1>
      <loading-area v-if="isLoading" :is-loading="isLoading">
        <span><translate translate-context="*/*/Loader">Loading organization data…</translate></span>
      </loading-area>
      <form v-else @submit.prevent="update">
        <div class="stackable row">
          <div class="column">
            <h2>General information</h2>
            <div class="field">
              <label for="name">
                <translate translate-context="*/*/*">Name</translate>
              </label>
              <field-errors :errors="errors.fieldErrors" field="name" />
              <input
                type="text"
                required
                placeholder="Company Name"
                id="name"
                name="name"
                v-model="obj.name">
            </div>
            <div class="field">
              <label>
                <translate translate-context="*/*/*">Contact email</translate>
              </label>
              <field-errors :errors="errors.fieldErrors" field="email" />
              <input
                type="email"
                id="email"
                name="email"
                required
                v-model="obj.email">
            </div>
            <div class="field">
              <label for="vat-number">
                <translate translate-context="*/*/*">VAT Number</translate>
              </label>
              <field-errors :errors="errors.fieldErrors" field="vat_number" />
              <input
                type="text"
                id="vat_number"
                name="vat_number"
                v-model="obj.vat_number">
            </div>
          </div>
          <div class="column ml-large">
            <h2>Address</h2>
            <div class="field">
              <label for="address-name">
                <translate translate-context="*/*/*">Name</translate>
              </label>
              <field-errors :errors="errors.fieldErrors" field="address.name" />
              <input
                type="text"
                required
                placeholder="Company Name"
                id="address-name"
                name="address-name"
                v-model="obj.address.name">
            </div>
            <div class="field">
              <label for="address-address">
                <translate translate-context="*/*/*">Address</translate>
              </label>
              <field-errors :errors="errors.fieldErrors" field="address.address" />
              <textarea
                id="address-address"
                name="address-address"
                required
                rows="4"
                v-model="obj.address.address"></textarea>
            </div>
            <div class="field">
              <label for="address-locality">
                <translate translate-context="*/*/*">City/Locality</translate>
              </label>
              <field-errors :errors="errors.fieldErrors" field="address.locality" />
              <input
                type="text"
                id="address-locality"
                name="address-locality"
                required
                v-model="obj.address.locality">
            </div>
            <div class="field">
              <label for="address-postal_code">
                <translate translate-context="*/*/*">Postal Code</translate>
              </label>
              <field-errors :errors="errors.fieldErrors" field="address.postal_code" />
              <input
                type="text"
                id="address-postal_code"
                name="address-postal_code"
                required
                v-model="obj.address.postal_code">
            </div>
            <div class="field">
              <label for="address-country">
                <translate translate-context="*/*/*">Country Code</translate>
              </label>
              <field-errors :errors="errors.fieldErrors" field="address.country" />
              <input
                type="text"
                id="address-country"
                name="address-country"
                placeholder="FR, DE, UK, US…"
                required
                v-model="obj.address.country.code">
            </div>
          </div>
        </div>
        <app-button :is-loading="isUpdating" type="submit">
          <translate translate-context="*/Button/Verb">Update</translate>
        </app-button>
      </form>
    </section>
  </div>
</template>
<script>

import http from '@/http'

export default {
  data () {
    return {
      errors: {},
      isLoading: false,
      isUpdating: false,
      obj: null,
    }
  },
  async created () {    
    await this.fetchData()
  },
  methods: {
    
    async fetchData() {
      let response
      this.isLoading = true
      this.errors = {}
      this.obj = null
      try {
        response = await http.get('organization')
        this.obj = response.data
        this.obj.address = this.obj.address || {
          name: this.obj.name || "",
          address: "",
          locality: "",
          postal_code: "",
          country: {code: ""},
        }
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async update() {
      this.isUpdating = true
      this.errors = {}
      this.obj.vat_number = this.obj.vat_number || null
      try {
        await http.post('organization', this.obj)
        await this.fetchData()
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isUpdating = false
      }
    },
  }
}
</script>
